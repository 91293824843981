<template>
    <v-row>
        <v-dialog
        v-model="show.show"
        persistent
        max-width="500"
        v-if="resolutionScreen >= 500"
        >
            <v-card>
                <v-card-text class="pa-0">
                <v-list class=" pa-0">
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title style="font-size:large;" class="font-weight-bold">
                            
                        <v-icon size="35" dense dark :color="color.theme" class="pr-2">mdi-image-edit</v-icon>
                        {{ $t('logo_feature.consent_header_change') }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <!-- <v-list-item-action>
                        <v-icon size="35" dense dark :color="color.theme">mdi-image-edit</v-icon>
                    </v-list-item-action> -->
                    </v-list-item>
                </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pt-5">
                    <v-layout  align-center justify-center v-if="!saved_changed">
                        <span class="text-body-1">
                            {{ show.status === 'SD' ? $t('logo_feature.default_detail') : $t('logo_feature.change_detail') }}
                        </span>
                    </v-layout>
                    <v-layout  align-center justify-center v-else>
                        <span class="text-body-1">
                            {{ $t('logo_feature.save_complete') }}
                        </span>
                    </v-layout>
                    
                </v-card-text>
                <v-card-actions>
                    <v-progress-circular
                    v-if="isloading"
                    indeterminate
                    width="3"
                    size="25"
                     :color="color.theme"
                    />
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="isloading || saved_changed"
                    color="red"
                    outlined
                    @click="close_consent()"
                    >{{ $t("checkpassword.cancel") }}</v-btn
                >
                <v-btn :disabled="isloading || saved_changed" :color="color.theme" :dark="isloading || saved_changed ? false : true" @click="fn_managelogo(show)">
                    {{ show.status === 'SD' ? $t('logo_feature.default_consent') : $t('logo_feature.change_consent') }}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        v-model="show.show"
        persistent
        :max-width="maxWidthOnMobile"
        v-else
        >
        <v-card>
            <v-card-text class="pa-5">
            <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-6">
                <span :style="headerPage">{{ $t('logo_feature.consent_header_change') }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
                <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('cancelChnage')"
                :disabled="isloading"
                >mdi-close</v-icon
                >
            </v-flex>
            </v-layout>
            </v-card-text>
            <v-card-text class="px-4 pt-1 pb-1 text-center" :style="titleText" v-if="!saved_changed">
                <p>{{ show.status === 'SD' ? $t('logo_feature.default_detail') : $t('logo_feature.change_detail') }}</p>
            </v-card-text>
            <v-card-text class="px-4 pt-1 pb-1 text-center" :style="titleText" v-else>
                <p>{{ $t('logo_feature.save_complete') }}</p>
            </v-card-text>
            <div class="text-center py-4">
                <v-btn
                :style="btnAction"
                class="mr-6"
                width="40%"
                :disabled="isloading || saved_changed"
                color="red"
                outlined
                small
                @click="close_consent()"
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              width="40%"
              small
              :disabled="isloading || saved_changed" :color="color.theme" :dark="isloading || saved_changed ? false : true" @click="fn_managelogo(show)"
              >{{ show.status === 'SD' ? $t('logo_feature.default_consent') : $t('logo_feature.change_consent') }}</v-btn
            >
            </div>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
export default {
    props: ["show"],
    computed: {
        ...mapState(["username", "authorize", "account_active", "service", "color"]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataStorageUsage",
        "dataStorageMax",
        ]),
        resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return 220;
            case "sm":
            return 400;
            case "md":
            return 500;
            case "lg":
            return 600;
            case "xl":
            return 800;
            }
      },
      maxWidthOnMobile() {
        if(this.resolutionScreen >= 400) {
            return 600
        } else {
            return 346
        }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    },

    data: function () {
        return {
            base64String: "",
            isloading: false,
            saved_changed: false
        }
    },

    methods: {
        async fn_managelogo (payload_manage) {
            // ตั้งค่ากลับไปเป็น logo ภาพที่เลือก
            this.isloading = true
            let payload_image = payload_manage.payload
            console.log("PAYLOAD ", payload_image)
            let auth = await gbfGenerate.generateToken();
            try {
            await this.axios
            // process.env.VUE_APP_SERVICE_ADMIN_BOX http://192.168.73.245:8811process.env.
                .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/set_image_logo", payload_image, {headers: { Authorization: auth.code },})
                .then((response) => { 
                    if(response.data.data.message === 'Insert logo business success' || 
                        response.data.data.message === 'Update Logo business success'){
                        console.log("response ", response.data)
                        //  ต้องมีการ set state ของ logo_default
                        let favicon = document.querySelector('link[rel="icon"]')
                        if (!favicon) {
                            console.log("เข้านี้บ้างมั้ย1")
                            favicon = document.createElement('link')
                            favicon.setAttribute('rel', 'icon')
                            favicon.setAttribute('type', `image/${response.data.data.picture.split(';')[0].split('/')[1]}`)
                            document.head.appendChild(favicon)
                        } else {
                            favicon.setAttribute('type', `image/${response.data.data.picture.split(';')[0].split('/')[1]}`)
                            favicon.setAttribute('href', response.data.data.picture)
                            
                        }
                        this.$store
                        .dispatch("change_default_logo", response.data.data.picture)
                        .then(async (msg) => {
                            if(msg.status === 'OK'){
                                this.isloading = false
                                this.saved_changed = true
                                setTimeout(() => {
                                    this.$emit('closeAccept', {status_accept: payload_manage.status})
                                }, 1200)
                            }
                        })
                    } else {
                        this.isloading = false
                        this.$emit('closeAccept', {status_accept: 'ERR'})
                    }   
                })
                .catch((err)=>{
                    console.log("ERROR ", err)
                    this.isloading = false
                    this.$emit('closeAccept', {status_accept: 'ERR'})
                })
            } catch (err) {
                this.isloading = false
                this.$emit('closeAccept', {status_accept: 'ERR'})
            }
                
        },
        close_consent () {
            this.$emit('cancelChnage')
        }
        
    }

}
</script>

<style>

</style>